import './css/conta.scss'
import { useState, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import { APP_NAME } from './helpers/constants'

function Landing() {

  const fromapp = (new URLSearchParams(useLocation().search)).get('fromapp');
  const [ state, setState ] = useState({
    loading_user: true,
    loading_planos: true
  })
  const [ planoSelecionado, selectPlano ] = useState(null)
  // const [ recorrente, setRecorrente ] = useState(false)
  const [ planos, setPlanos ] = useState([])
  const [ compra, setCompra ] = useState(null)
  const [ user, setUser ] = useState({})
  const [ intervalStatusCompra, setIntervalStatusCompra ] = useState(null)
  const history = useHistory()

  const updateUser = () => {
    setState(s => ({...s, loading_user: true}))
    axios.get('/user').then(({data}) => {
      setState(s => ({...s, loading_user: false}))
      setUser(data)
    }).catch(e => {
      setState(s => ({...s, loading_user: false}))
      setUser(null)
    })
  }

  useEffect(() => {
    updateUser()
    axios.get('/assinatura/planos').then(({data}) => {
      setState(s => ({...s, loading_planos: false}))
      setPlanos(data)
    }).catch(() => {})
  }, [])

  const sair = () => {
    window.api_key = null
    window.user = null
    delete(axios.defaults.headers.common.Authorization)
    history.replace('/hotsite')
  }

  const confirmar = () => {
    axios.post(`assinatura/assinar/${planoSelecionado._id}`, {
    // recorrente
    }).then(({data}) => {
      setCompra(data)
    }).catch(e => {
      alert("Estamos com problemas para fazer a assinatura!")
      console.error(e)
    })
  }

  const updateCompraStatus = () => {
    if (compra) {
      axios.get(`assinatura/compra/${compra._id}`).then(({data}) => {
        setCompra(c => ({
          ...c,
          status: data.status
        }))
        if (data.status !== 'w') {
          updateUser()
          setTimeout(() => setCompra(null), 2000)
        }
      }).catch(e => {
        console.error(e)
      })
    }
  }

  useEffect(() => {
    if (compra && compra.status === 'w') {
      if (!intervalStatusCompra)
      setIntervalStatusCompra(setInterval(() => updateCompraStatus(), 2000))
    } else {
      if (intervalStatusCompra) {
        clearInterval(intervalStatusCompra)
        setIntervalStatusCompra(null)
      }
    }
  // eslint-disable-next-line
  }, [compra])

  const renderCompra  = () => {
    switch (compra.status) {
      case 'n': // Nova compra
        return <div>
          <h4>Compra</h4>
          <p>Criando compra...</p>
        </div>;
      case 'w': // Aguardando pagamento
        return <div>
          <h4>Compra {compra._id}</h4>
          <p>Aguardando pagamento...</p>
          <div style={{
            width: '100vw',
            height: '100vh',
            background: 'rgba(0,0,0,.7)',
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            top: 0,
            left: 0,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <h4 style={{ color: '#fff' }}>Pagamento</h4>
            <iframe title="Pagamento Cielo" src={compra.checkoutUrl} style={{
              width: '600px',
              maxWidth: '100%',
              height: '600px',
              maxHeight: '70%',
              background: 'white'
            }} frameBorder="0"></iframe>
            <div style={{ margin: '10px' }}>
              <button onClick={cancelarCompra}>Cancelar compra</button>
            </div>
          </div>
        </div>;
      case 'f': // Pagamento efetuado
        return <div>
          <h4>Compra</h4>
          Pagamento efetuado com sucesso!
        </div>;
      case 'c': // Compra cancelada
        return <div>
          <h4>Compra</h4>
          A compra foi cancelada!
        </div>;
      default:
        return <div>Aqui vai renderizar a compra</div>;
    }
  }

  const cancelarCompra = () => {
    axios.delete(`assinatura/compra/${compra._id}`).then(({data}) => {
      setCompra(null)
      if(intervalStatusCompra) clearInterval(intervalStatusCompra)
    }).catch(e => {
      console.error(e)
    })
  }

  const formatador = Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })

  return (
    state.loading_user ? (<div id="loading_conta">
      <h1>{ APP_NAME }</h1>
      <span>Carregando...</span>
    </div>) : (user === null ? (
      <Redirect path="/hotsite" />
    ) : (<div id="conta">
      <div id="header">
        <h4>{ APP_NAME }</h4>
        <button className='small secundario outlined' onClick={sair}>Sair</button>
      </div>
      <div style={{ textAlign: 'center' }}>
        <h2>Olá, {user.name}!</h2>
      </div>
      { user.assinatura_ativa ? (<div>
        <span style={{ color: "#aaf" }}>Você tem acesso completo ao { APP_NAME }.</span><br />
        Sua assinatura expirará em {
          `${Intl.DateTimeFormat('pt-BR').format(new Date(user.assinatura_vencimento))}`
        }.
      </div>) : (<div>
        <div style={{ color: '#faa', maxWidth: '400px', margin: 'auto' }}>Você ainda não assinou o { APP_NAME } e só tem acesso aos órgãos de exemplo.</div>
      </div>) }
      { state.loading_planos || planos.length > 0 ? (<div id="planos">
        <h2>Planos</h2>
        <span>Todas as assinaturas incluem acesso a todos os órgãos.</span>
        <table><tbody>
          { state.loading_planos
            ? (<tr><td>Carregando...</td></tr>)
            : planos.map(plano => (<tr key={plano._id} className="plano">
            <td>
              <span className="nome">{plano.nome}</span><br/>
              { plano.descricao ? <span className="descricao">{plano.descricao}</span> : null }
            </td>
            <td className="valor">{formatador.format(plano.preco)}</td>
            <td width="50px"><button onClick={() => selectPlano(plano)}>{ user.assinatura_ativa ? 'Renovar' : 'Assinar' }</button></td>
          </tr>)) }
        </tbody></table>
      </div>) : null }
      { (planoSelecionado && !compra) ? (<div id="compra">
        <h2>Assinar</h2>
        <div id="dados" className="plano">
          <div style={{ display: 'flex', flexDirection: 'row', placeItems: 'center', justifyContent: 'space-between' }}>
            <div>
              <div className="nome">{ planoSelecionado.nome }</div>
              {planoSelecionado.descricao ? <div className="descricao">{ planoSelecionado.descricao }</div> : null }
            </div>
            <h3 className="valor">{ formatador.format(planoSelecionado.preco) }</h3>
          </div>
          {/* <div style={{ margin: '1em 0 .5em' }}>
            <label>
              <input type="checkbox" checked={recorrente}
                onChange={e => setRecorrente(e.target.checked)} /> Ativar renovação automática
            </label>
          </div> */}
          <button onClick={() => selectPlano(null)} className="secundario outlined">Cancelar</button>
          <button onClick={() => confirmar()}>Confirmar</button>
        </div>
      </div>) : null }
      { compra ? renderCompra() : null }
      { !fromapp ? (
        <div>
          <h2 style={{ margin: '2em auto 1em' }}>Obter App</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <a href="https://www.apple.com/br/app-store/" target="_blank" rel="noopener noreferrer">
              <img height='65px' src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/1280px-Download_on_the_App_Store_Badge.svg.png" alt=""/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=arlandantas.traumascoring" target="_blank" rel="noopener noreferrer">
              <img height='100px' src="https://play.google.com/intl/pt-BR/badges/static/images/badges/pt-br_badge_web_generic.png" alt=""/>
            </a>
          </div>
        </div>
      ) : null }
    </div>))
  );
}

export default Landing;
