import './css/index.scss'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import { APP_NAME } from './helpers/constants'

function Landing() {
  let history = useHistory()

  let [form, setForm] = useState({
    name: '',
    username: '',
    password: '',
    confirmPassword: '',
    tipo: 'signin',
    err: null
  });

  const erroForm = (msg) => {
    setForm(f => ({...f, err: msg}))
  }

  const submitForm = () => {
    if (form.tipo === 'signup' && !/.+\s.+/.test(form.name)) {
      document.getElementById('txt_nome').focus()
      return erroForm("Por favor, insira um nome válido!")
    }
    if (form.tipo === 'signup' && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(form.username)) {
      document.getElementById('txt_email').focus()
      return erroForm("Por favor, insira um email válido!")
    }
    if (!form.password || form.password.length < 3) {
      document.getElementById('txt_password').focus()
      return erroForm("Por favor, insira uma senha válida!")
    }
    if (form.tipo === 'signup' && form.password !== form.confirmPassword) {
      document.getElementById('txt_password').focus()
      return erroForm("As senhas não coincidem!")
    }
    axios.post('/login/'+(form.tipo === 'signup' ? 'signup' : ''), form)
      .then(({data}) => {
        window.api_token = `Bearer ${data.token}`
        axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
        window.user = data
        history.push('/conta')
      }).catch(({response}) => {
        if (response.data === 'email_existente') {
          if (window.confirm('Já existe um cadastro usando esse email, deseja fazer login?')) {
            setForm(f => ({...f, tipo: 'signin'}))
          }
        } else if (response.data === 'login_invalido') {
          erroForm("Os dados informados não coincidem")
          document.getElementById('txt_email').focus()
        } else {
          console.log(response.data)
          erroForm("Estamos com problemas!")
        }
      })
  }

  const esqueci = () => {
    alert("Em desenvolvimento...")
  }

  return (
    <div id="index">
      <div className="page">
        <h1 style={{ marginBottom: 0 }}>{ APP_NAME }</h1>
        <h2 style={{ fontWeight: 400 }}>Classificação de traumas na palma da sua mão.</h2>
      </div>
      <div className="page" id="planos">
        <h1 style={{ margin: '0 0 1em' }}>Planos</h1>
        <div id="planos">
          <div className="plano">
            <h2>Gratuito</h2>
            <ul>
              <li>Acesso à classificações de Baço AAST e CTSI</li>
              <li>Classificações ilimitadas</li>
            </ul>
            {/* <button>Criar conta</button> */}
          </div>
          <div className="plano primario">
            <h2>Premium</h2>
            <ul>
              <li>Acesso à lista completa de órgãos</li>
              <li>Classificações ilimitadas</li>
              <li>Atualizações frequentes de órgãos e questionários</li>
            </ul>
            {/* <button>Criar conta</button> */}
          </div>
        </div>
      </div>
      <div className="page" id="conta">
        <h1 style={{ margin: '0 0 1em' }}>Acesso</h1>
        <div id="form">
          <div id="seletor">
            <span className={form.tipo === 'signup' ? `ativo` : ''} onClick={() => setForm((f) => ({...f, tipo: 'signup'}))}>Criar Conta</span>
            <span className={form.tipo === 'signin' ? `ativo` : ''} onClick={() => setForm((f) => ({...f, tipo: 'signin'}))}>Já tenho conta</span>
          </div>
          { form.err ? (<div id="erro">{form.err}</div>) : null }
          { form.tipo === 'signup' ? (<div className="field">
            <b>Nome completo:</b>
            <input id="txt_nome" type="text" value={form.name} onInput={(e) => setForm((f) => ({...f, name: e.target.value }))}/>
          </div>) : null }
          <div className="field">
            <b>Email:</b>
            <input id="txt_email" name="username" value={form.username} onInput={(e) => setForm((f) => ({...f, username: e.target.value }))}/>
          </div>
          <div className="field">
            <b>Senha:</b>
            <input id="txt_password" type="password" value={form.password} onInput={(e) => setForm((f) => ({...f, password: e.target.value }))}/>
          </div>
          { form.tipo === 'signup' ? (<div className="field">
            <b>Confirme sua senha:</b>
            <input type="password" value={form.confirmPassword} onInput={(e) => setForm((f) => ({...f, confirmPassword: e.target.value }))}/>
          </div>) : null }
          <div id="botoes">
            <button onClick={submitForm}>
              { form.tipo === 'signup' ? 'Criar' : 'Entrar' }
            </button><br/>
            { form.tipo === 'signin' ? <button className="secundario" onClick={esqueci}>Esqueci minha senha</button> : null }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
