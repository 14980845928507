import { HashRouter as Router, Redirect, Switch, Route } from 'react-router-dom'
import { logado } from './login'
import Landing from './landing'
import Conta from './conta'
import PaymentConfirmation from './paymentConfirmation'
import ContaFromApp from './contaFromApp'

function Protect ({ children }) {
  return (logado()
    ? children
    : (<Redirect to={{
      pathname: '/'
    }} />))
}

function App() {
  return (
    <Router>
      {/* <CssBaseline /> */}
      <Switch>
        <Route path="/conta">
          <Protect><Conta /></Protect>
        </Route>
        <Route path="/fromapp/:key">
          <ContaFromApp />
        </Route>
        <Route path="/hotsite">
          <Landing />
        </Route>
        <Route path="/payment_confirmation">
          <PaymentConfirmation />
        </Route>
        <Route path="/">
          <Landing />
        </Route>
        <Route path="*">
          <Redirect to={{ pathname: '/' }} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
