import axios from "axios";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { APP_NAME } from "./helpers/constants";

export default function ContaFromApp () {
  let { key } = useParams()
  let history = useHistory()

  useEffect(function () {
    axios.get('login/me', { headers: {
      Authorization:`Bearer ${key}`
    } }).then(({data}) => {
      window.api_token = `Bearer ${key}`
      axios.defaults.headers.common.Authorization = `Bearer ${key}`
      // axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
      window.user = data
      history.replace('/conta?fromapp=1')
    }).catch(e => {
      history.replace('/')
    })

    return () => {}
  })
  return <div id="loading_conta">
    <h1>{APP_NAME}</h1>
    <span>Carregando...</span>
  </div>
}