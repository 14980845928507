import axios from 'axios'

export const logado = () => {
  return !!window.api_token
}
export const getToken = () => {
  return window.api_token
}
export const login = (token, keep = false) => {
  window.api_token = token
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
  if (keep)
    localStorage.setItem('api_token', token)
}
export const logout = () => {
  window.api_token = null
  localStorage.removeItem('api_token')
}